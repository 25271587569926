<template>
  <ion-row
    class="ion-align-items-center bg-primary text-white border-radius mt-1 pt-1 pb-1"
    v-if="displaySelector"
    @click="goToTenantList"
  >
    <ion-icon size="small" :icon="chevronBackOutline" />
    <ion-avatar class="ml-1 mr-1 avatar">
      <img :src="tenantLogo" />
    </ion-avatar>
    <ion-col>
      <ion-label class="fw-500 two-line-text">{{ tenantName }}</ion-label>
    </ion-col>
  </ion-row>
</template>

<script>
import { IMAGE_PATH } from '@/modules/shared/constants/';
import { getImage } from '@/utils/';
import { chevronBackOutline } from 'ionicons/icons';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('b2b/customer');

export default defineComponent({
  name: 'TenantSelector',
  setup() {
    const storage = inject('$storage');

    const user = ref(null);
    const tenant = ref(null);
    const getUser = async () => {
      user.value = await storage.getUser();
      tenant.value = user.value?.tenant;
    };

    const selectedCompany = ref(null);
    const getSelectedCompany = async () => {
      selectedCompany.value = await storage.getSelectedCompany();
    };

    const tenantName = computed(() => tenant.value?.tenant?.name ?? '');
    const tenantLogo = computed(() => getImage(tenant.value?.logo, IMAGE_PATH.SUPPLIER_LOGO));

    onMounted(async () => {
      await getUser();
      await getSelectedCompany();
    });

    return {
      chevronBackOutline,
      tenantName,
      tenantLogo,
      user,
      selectedCompany
    };
  },
  computed: {
    ...mapGetters(['customerTenants']),

    isInAllowedPages() {
      const path = this.$route.fullPath;
      const allowedPages = ['/home', '/order', '/cart'];
      return allowedPages.some((page) => path.indexOf(page) > -1);
    },

    isB2cBuyer() {
      const isBuyer = this.$route.fullPath.indexOf('/b2b') > -1;
      if (!isBuyer) return false;

      if (!this.user?.is_b2c || !this.selectedCompany?.is_b2c) {
        return false;
      }

      return true;
    },

    displaySelector() {
      if (!this.isB2cBuyer) return false;
      if (!this.isInAllowedPages) return false;

      return this.customerTenants.length > 1;
    }
  },
  methods: {
    goToTenantList() {
      this.$router.push(`/b2b/stores`);
    }
  }
});
</script>

<style lang="scss" scoped>
.two-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.border-radius {
  border-radius: 8px;
}
.avatar {
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
